<template>
  <div class="watch-menu">
    <ul class="horizontal-nav">
      <li>
        <router-link :to="{ path: '/base/member/detail/' + memberId + '/watch/66' }">生命线</router-link>
      </li>
      <li>
        <router-link :to="{ path: '/base/member/detail/' + memberId + '/watch/60' }">睡眠</router-link>
      </li>
      <li>
        <router-link :to="{ path: '/base/member/detail/' + memberId + '/watch/61' }">心率</router-link>
      </li>
      <li>
        <router-link :to="{ path: '/base/member/detail/' + memberId + '/watch/62' }">血氧</router-link>
      </li>
      <li>
        <router-link :to="{ path: '/base/member/detail/' + memberId + '/watch/63' }">血压</router-link>
      </li>
    </ul>
    <div class="tab-contain">
      <a :class="['static-button', { active: currentIndex === 0 }]" @click="handleClickType(0)">数据显示</a>
      <a :class="['chart-button', { active: currentIndex === 1 }]" @click="handleClickType(1)">图表显示</a>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
export default {
	data() {
		return {
			currentIndex: 1,
		};
	},
	computed: {
		memberId() {
			return this.$route.params.member_id;
		},
	},
	methods: {
		handleClickType(index) {
			this.currentIndex = index;
			// if (index === 0) {
			//   this.$router.push({ query: { type: 'table' } })
			// } else {
			//   this.$router.push({ query: null })
			// }
		},
	},
};
</script>
<style lang="less" scoped>
.watch-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 10px 20px;

  .tab-contain a {
    padding: 6px;
    border: 1px solid #333543;
    color: #999;
    display: inline-block;
    font-size: 12px;
  }

  .tab-contain .static-button {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-right: none;
  }

  .tab-contain .chart-button {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-left: none;
  }

  .tab-contain .active {
    background-color: #333543;
    color: #fff;
  }
}

.horizontal-nav {
  height: 44px;
  display: inline-block;
}

.horizontal-nav>li {
  float: left;
  height: 44px;
  line-height: 44px;
  margin-right: 28px;
}

.horizontal-nav>li>a {
  display: block;
  padding: 0 20px;
  color: #666;
}

.horizontal-nav>li>a.active {
  color: #ff8400;
  border-bottom: 2px solid #ff8400;
}

.statistics-container .content-in {
  padding: 20px;
  background-color: #fff;
}
</style>
