<template>
  <div class="scheme-contain">
    <watch-sub-Menu></watch-sub-Menu>
    <router-view></router-view>
  </div>
</template>

<script type="text/ecmascript-6">
import WatchSubMenu from 'pages/member/common/watch-menu';

export default {
	components: { WatchSubMenu },
};
</script>

<style lang="less">
.watch-page {
  background-color: #e9ebec;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(9, 100px);
  gap: 20px;
  padding-top: 20px;
  .watch-chart {
    grid-column: 1 / span 2;
    grid-row-start: span 3;
  }
  .grid-span-1 {
    grid-row-start: span 1;
  }
  .grid-span-2 {
    grid-row-start: span 2;
  }
  .grid-span-3 {
    grid-row-start: span 3;
  }
}
</style>
